<template>
  <div
    class="product-search-row d-flex justify-space-between align-center w-100 py-1"
    @click="goToProductDetail"
  >
    <v-list-item-avatar width="70" height="70" rounded="0" class="my-0 mr-1">
      <img
        :src="product.mediaURL"
        :alt="product.name"
        onerror="this.onerror=null;this.src='/no-icon.png'"
      />
    </v-list-item-avatar>
    <v-list-item-content class="default--text pa-0 pa-sm-2">
      <span class="product-name text-caption">
        {{ product.name }}
      </span>
      <v-list-item-subtitle
        class="product-short-descr font-weight-bold text-uppercase text-caption"
      >
        {{ product.shortDescr }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action class="w-100 flex-row align-center mx-0">
      <div class="price d-flex flex-column align-center ml-1 mr-2">
        <ProductPrice v-if="product.priceDisplay" :product="product" />
      </div>
      <v-btn
        color="primary"
        depressed
        height="40"
        class="add-to-cart"
        @click.stop.prevent="addToCart(quantity)"
      >
        <v-icon>$cart</v-icon>
      </v-btn>
    </v-list-item-action>
  </div>
</template>
<style lang="scss">
.product-search-row {
  .price {
    min-width: 100px;
  }
  .product-name {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .product-price {
    .old-price {
      font-size: 0.875rem;
      line-height: 0.875;
    }
    .cur-price {
      font-size: 1.15rem;
    }
  }
  .v-list-item__action {
    width: fit-content;
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPriceLeaflet.vue";

import productMixin from "~/mixins/product";

export default {
  name: "ProductSearchRow",
  mixins: [productMixin],
  components: { ProductPrice },
  props: {
    product: { type: Object, required: true }
  },
  methods: {
    goToProductDetail() {
      this.$emit("goToProductDetail", this.product);
    }
  },
  data() {
    return {
      internalQuantity: -1
    };
  }
};
</script>
